.slider-product-container {
  max-width: 120rem;
  margin: auto;

  &__section-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #408F96;
    margin-bottom: 1rem;
    padding: 2rem;
    text-align: center;
  }

  .border {
    border: #f0f0f0 1px solid;
    padding: 1rem;
    margin-top: 1rem;
  }

  .swiper-container {
    position: relative;
    overflow: hidden;

    .testezinho {
      display: flex;
      justify-content: center;
    }
  }

  .arrow-left-right {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
      color: #408F96;
      opacity: 0.5;
      cursor: pointer;
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background-color: transparent;
      color: #408F96;
      opacity: 1;
      cursor: pointer;
    }

    .custom-icon {
      opacity: 0.5;
    }
  }

  .no-products-found {
    text-align: center;
    padding: 3rem;
    opacity: 0.7;
    color: #808080;
  }

  .input-search {
    width: 100%;
    padding: 0.5rem;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
    margin-top: 0.5rem;
    box-sizing: border-box;
    color: #333;
    font-family: 'Arial', sans-serif;
  }

  .input-search::placeholder {
    color: #999; 
    font-style: italic;
    opacity: 0.8;
  }

  @media (max-width: 700px) {
    .slider-product-container {
      max-width: 60rem;
    }
  }

  @media (max-width: 350px) {
    .slider-product-container {
      max-width: 40rem;
    }
  }
}
