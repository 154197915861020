.navbar-container {
  background-color: #408f96;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;

  &__content {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    max-width: 100rem;
    padding: 1rem;
  }

  &__logo {
    width: 15rem;
    padding: 0.8rem 0;
  }

  .close-menu-button {
    display: none;
  }

  &__products-contact {
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 700px) {
      display: none;
      flex-direction: column;
      gap: 1rem;
      width: 50%;
      background-color: #408f96;
      position: absolute;
      top: 12rem;
      right: 0;
      padding: 1rem 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

      &.open {
        display: flex;
        padding-bottom: 2rem;
      }

      .close-menu-button {
        display: block;
        align-self: flex-end;
        background-color: transparent;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        padding: 0.5rem;
        margin-bottom: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 0.4rem;

    @media (max-width: 700px) {
      display: flex;
    }

    &-bar {
      width: 2rem;
      height: 0.2rem;
      background-color: white;
    }
  }
}
