.products-container {
  background-color: #408F96;
  padding-bottom: 5rem;

  &__content {
    display: flex;
    margin: auto;
    margin-bottom: 5rem;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding: 4rem 0 0 0;
  }

  &__title-strong {
    color: #F7B800;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  &__subtitle-description {
    width: 100%;
    color: #F5F5F5;
    font-size: 1.4rem;
  }

  &__subtitle {
    display: flex;
    padding: 3rem;
    color: #F5F5F5;
    font-size: 1.4rem;
    padding: 2rem;
  }

  &__our-products-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  &__bg-icon {
    display: flex;
    background-color: #FBEBC5;
    padding: 2rem;
    border-radius: 50%;
    margin-bottom: 2rem;
  }
}

ul {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .products-container {
    &__content {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-bottom: 5rem;
    }

    &__title {
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 0 0;
    }

    &__title-strong {
      text-align: center;
      padding: 2rem;
      color: #F7B800;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    &__subtitle {
      display: flex;
      padding: 3rem;
      color: #F5F5F5;
      font-size: 1.4rem;
      padding: 1rem;
    }
  }
}