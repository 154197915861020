.footer-container {
  display: flex;
  justify-content: center;
  gap: 20rem;
  margin: auto;
  background-color: #408F96;
  color: white;
  width: 100%;
  padding: 2rem;


  &__social-medias {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__word {
    font-size: 1.4rem;
  }
}

@media (max-width: 700px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    margin: auto;
    background-color: #408F96;
    color: white;
    width: 100%;
    padding: 2rem;
  
    &__social-medias {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      gap: 1rem;
      max-width: 20rem;
    }
  
    &__title {
      font-size: 1.6rem;
      font-weight: 600;
    }
  
    &__word {
      font-size: 1.4rem;
    }
  }
}