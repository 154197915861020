.btn-contact-content {
  margin-top: 1rem;
}

.btn-contact {
  font-family: 'Inter', sans-serif;
  background-color: #F7B800;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 1.3rem 2.2rem;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-decoration: none;
}

.btn-contact:hover {
  background-color: #ffc720;
  cursor: pointer;
}