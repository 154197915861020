.slider-product-container {
  max-width: 120rem;
  margin: auto;

  &__section-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #408F96;
    margin-bottom: 1rem;
    padding: 2rem;
  }

  .testezinho {
    display: flex;
    padding: 2rem;

  }

  .swiper-container {
    position: relative;
    overflow: hidden;
  }
}

.border {
  position: relative;
  border: #f0f0f0 1px solid;
}

.arrow-left-right {
  background-color: #408F96;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: pink;
}

.custom-icon {
  opacity: 0.5;
}

@media (max-width: 700px) {
  .slider-product-container {
    max-width: 60rem;
    margin: auto;
  
    &__section-title {
      font-size: 2.5rem;
      font-weight: 600;
      color: #408F96;
      margin-bottom: 1rem;
      padding: 2rem;
    }
  
    .testezinho {
      display: flex;
      padding: 2rem;
  
    }
  
    .swiper-container {
      position: relative;
      overflow: hidden;
    }
  }
  
}