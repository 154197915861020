.article-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 10rem;

  font-family: 'Inter', sans-serif;

  &__title {
    font-size: 4.5rem;
    font-weight: 600;
    color: #408F96;
  }

  &__subtitle {
    font-size: 4.5rem;
    font-weight: 600;
    color: #F7B800;
  }

  &__text {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #717171;
    max-width: 35rem;
  }

  &__btn {
    font-family: 'Inter', sans-serif;

    margin-top: 3rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.3rem;
    font-weight: 500;
    background-color: #408F96;
    color: white;
  }
}

.article-container__hero-img {
  width: 40rem;
}

@media (max-width: 700px) {
  .article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 10rem;
  
    font-family: 'Inter', sans-serif;

    &__title-mobile {
      padding: 2rem                                            ;
    }
  
    &__title {
      font-size: 3.5rem;
      font-weight: 600;
      color: #408F96;
    }
  
    &__subtitle {
      font-size: 3.5rem;
      font-weight: 600;
      color: #F7B800;
    }
  
    &__text {
      margin-top: 2rem;
      font-size: 1.2rem;
      color: #717171;
      max-width: 35rem;
    }
  
    &__btn {
      font-family: 'Inter', sans-serif;
  
      margin-top: 3rem;
      padding: 1rem 2rem;
      border: none;
      border-radius: 0.3rem;
      font-weight: 500;
      background-color: #408F96;
      color: white;
    }
  }
  
  .article-container__hero-img {
    margin-top: -15rem;
    width: 30rem;
  }
}