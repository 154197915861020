.footer-email-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
  padding: 2rem;

  &__title {
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__input-name,
  &__input-email, 
  &__textarea-products {
    margin-bottom: 0.5rem;
    background-color: #408F96;
    color: white;
    padding: 1rem;
    border: 1px solid white;
    border-radius: .7rem;
    width: 100%;
    resize: none;
  }

  &__textarea-products {
    height: 10rem;
  }

  &__input-name::placeholder,
  &__input-email::placeholder,
  &__textarea-products::placeholder {
    color: #e7e7e7;
  }

  &__submit {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  &__button-send {
    font-family: 'Inter', sans-serif;
    background-color: #F7B800;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 1.3rem 2.2rem;
    border: none;
    border-radius: 0.5rem;
    color: white;
    width: 100%;
  }

  &__button-send:hover {
    background-color: #ffc720;
    cursor: pointer;
  }
}