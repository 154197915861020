.card-product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2rem;
    height: 37rem;
    width: 35rem;
    border-radius: 2rem;
    -webkit-box-shadow: -2px 6px 24px -3px rgba(0,0,0,0.31);
    -moz-box-shadow: -2px 6px 24px -3px rgba(0,0,0,0.31);
    box-shadow: -2px 6px 24px -3px rgba(0,0,0,0.31);

    &__name-product {
      margin: 2rem 0rem;
      font-weight: 400;
      color: #408f96;
      font-size: 1.6rem;
      text-align: center;
      text-decoration: none;
      text-align: center;
    }
  }


@media (max-width: 500px) {
  .card-product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: 37rem;
    width: 30rem;
    -webkit-box-shadow: -2px 6px 24px -3px rgba(0, 0, 0, 0);
    -moz-box-shadow: -2px 6px 24px -3px rgba(0, 0, 0, 0);
    box-shadow: -2px 6px 24px -3px rgba(0, 0, 0, 0);

    &__name-product {
      margin: 2rem 0rem;
      font-weight: 400;
      color: #408f96;
      font-size: 1.4rem;
      text-align: center;
      text-decoration: none;
      text-align: center;
    }

    &__img-product {
      width: 15rem;
    }
  }

}