.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  padding-bottom: 2rem;

  &__h1 {
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
    color: #408f96;
    margin: 4rem 0 2rem 0;

    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }

  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .section-container__card-container {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .section-container__card-content {
    position: relative;
  }

  .section-container__img-card {
    max-width: 30rem;
    object-fit: cover;
    width: 100%;
    height: 20rem;
    display: block;
    border-radius: 1rem;
  }

  .section-container__title-card {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 600;
    color: white;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 30%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 10px;
    text-align: center;
    border-radius: 0 0 1rem 1rem;
  }
}
